

























































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useGetters } from 'vuex-composition-helpers';

import { parseISO } from 'date-fns';

import { Report } from '@/models/report.model';
import { GET_REPORTS } from '@/store/report.actions';
import ReportList from '@/components/ReportList.vue';

export default defineComponent({
  name: 'ReportListView',
  components: {
    ReportList
  },
  setup: () => {
    const getters = useGetters([GET_REPORTS]);
    const reports = getters[GET_REPORTS];

    const today = new Date();
    const selectedYear = ref(today.getFullYear());
    const allYears = [] as number[];
    reports.value.forEach((report: Report) => {
      const dateStr = report.zeitstempel;
      if (!dateStr || dateStr.length === 0) {
        return;
      }

      const date = parseISO(dateStr);
      allYears.push(date.getFullYear());
    });

    const years = [...new Set(allYears)].sort((l, r) => r - l);
    const filteredReports = computed(() => {
      return (reports.value as Report[]).filter(report => {
        if (selectedYear.value == null) {
          return true;
        }

        const dateStr = report.zeitstempel;
        if (!dateStr || dateStr.length === 0) {
          return false;
        }

        const date = parseISO(dateStr);

        // Frühestens Datum ausschalten
        // const earliestDate = subYears(today, 10);
        // if (isBefore(date, earliestDate)) {
        //   return false;
        // }

        const dateYear = date.getFullYear();
        if (selectedYear.value == null) {
          return (
            today.getFullYear() === dateYear // aktuelles Jahr als Referenz
          );
        }

        return dateYear === selectedYear.value;
      });
    });

    return {
      filteredReports,

      years,
      selectedYear
    };
  }
});
